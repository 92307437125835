import AJAX from "@core/ajax";
import { isArray } from "@utils/is";

export const TRANSITION_COLORS = [];

export const fetchColors = () => {
  AJAX
    .get("transitions_routes")
    .then((data) => {
      if (data && isArray(data))
        data.forEach(({ url, color }) => TRANSITION_COLORS.push({ url, color }));
    })
    .catch(() => {});
};

export const findColorByPath = url => {
  // find page by URL
  const route = TRANSITION_COLORS.find(route => route.url === url);

  // if a route match url, return color
  if (route) return route.color;

  return null;
};
